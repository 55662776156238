/**
 * 角色管理模块
 */

import request from '@/utils/request'
import axios2 from "@/utils/request_2"




//get 请求
/* 获取角色列表*/
export const getRoleList = (page = 0,name) => {
  let params = {
    page
  }
  !!name && (params.name = name );
  return request({
    method: 'get',
    url: '/role/list',
    params
  })
}






// 获取菜单数据
export const getMenuList = () => {
  return request({
    method: 'get',
    url: 'site/menu',

  })
}



// 获取校区数据
export const getSchoolList = () => {
  return request({
    method: 'get',
    url: '/site/school'
  })
}


// 获取班级数据
export const getClassList = (data) => {
  return request({
    method: 'post',
    url: '/site/classes',
    data
  })
}


// 新增角色
export const addRole = (data) => {
  return request({
    method: 'post',
    url: '/role/add',
    data
  })
}


// 角色详情获取
export const editData = (params) => {
  return request({
    method: 'get',
    url: '/role/edit',
    params
  })
}


// 删除角色
export const deleteRole = (data) => {
  return request({
    method: 'post',
    url: 'role/omit',
    data
  })
}

// 修改角色
export const editRole = (data) => {
  return request({
    method: 'post',
    url: 'role/edit',
    data
  })
}
//   列表分页
export const pageList = (params) => {
  return request({
    method: 'get',
    url: '/role/list',
    params
  })
}

export const getRoleList2 = (page = 1,query = {})=>{
  query.page = page;
  return axios2.get("/api/permission/role-list",{
    logic:1,
    params:query
  })
}
